import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import {initializeProviderLanguage} from "./language/init";
import React from 'react';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

initializeProviderLanguage();

import('@wtw/company-portal').then((Portal) => {
    root.render(
        Portal.default({
            headerIcon: <img src={"/images/customerlogo.png"} height={16} width={64} alt={"Logo"}/>
        })
    );
});